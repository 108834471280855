<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {

  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
*{
  padding: 0;
  margin: 0;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.text-left{
  text-align: left;
}
.flex{
  display: flex;
  align-items: center;
  .flex-col{
    flex: 1;
  }
}
.img_cover{
  width:100%;
  height:100%;
}
.main-banner{
  display: flex;
  img{
    width: 100%;
    height:600px;
  }
}
.van-popover{
  .van-popover__arrow{
    display: none !important;
  }
  .van-popover__content{
    background-color: rgba(0,0,0,0.5) !important;
    border-radius: 0 !important;
    padding: 10px 0;
    box-sizing: border-box;
  }
  .van-hairline--bottom::after{
    display: none !important;
  }
  .van-popover__action-text{
    color: rgba(255,255,255, 0.75) !important;
    line-height: 14px !important;
    font-weight: 400;
  }
  .van-popover__action{
    width: 190px !important;
    &:hover .van-popover__action-text{
      color: #fff !important;
      font-weight: 700;
    }
  }

}
.main-title .subtitle{
  font-family: SourceHanSansCN-Light;
  text-transform: uppercase;
  position: absolute;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.1);
  text-align: center;
  font-weight: 200;
  top: 3px;
  left: 50%;
  transform: translate(-50%, 30px);
}
.case-list{
  display: flex;
  margin-top: 30px;
  width: 1480px;
  flex-wrap: wrap;
  justify-content: space-between;
  .item{
    position: relative;
    width: 467px;
    height: 300px;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 40px;
    background-color: #eee;
    border-radius: 8px;
    .item-img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .item-mask{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 127px;
      z-index: 2;
      background: rgba(76,123,203,0.60);
      border-radius: 8px 8px 0 0;
      transform: translate(0, 68px);
      padding-left: 24px;
      padding-right: 24px;
      box-sizing: border-box;
      .item-mask-title{
        font-size: 20px;
        color: #FFFFFF;
        font-weight: 500;
        line-height: 60px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-height: 80px;
        overflow: hidden;
      }
      .item-mask-desc{
        padding-top: 5px;
        font-size: 14px;
        color: #FFFFFF;
        font-weight: 400;
        overflow: hidden;
        max-height: 48px;
        line-height: 20px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 5px;
      }  
      .item-mask-icon{
        img{
          width: 30px;
          height: 6px;
        }
      }
    }
    &:hover{
      .item-mask{
        animation: updown .3s linear forwards;
        height:96px;
      }
      .item-mask-title{
        line-height:28px;
        margin-top: 14px;
      }
      @keyframes updown {
        from{
          transform: translate(0, 45px);
        }
        to{
          transform: translate(0, 0);
        }
      }
    }
  }
}

.tab-card{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  .card-content{
    flex: 0 0 75px;
    height: 100%;
    border-bottom: 4px solid transparent;
    box-sizing: border-box;
    transition: all .3s;
    .item-icon{
      width: 100%;
      height: 75px;
      text-align: center;
      background-position: center 0;
      background-repeat: no-repeat;
      background-size: 75px auto;
    }
    .item-text{
      display: flex;
      height: calc(100% - 115px);
      align-items: center;
      justify-content: center;
      font-size: 28px;
      font-weight: 400;
      color: #000;
    }
    &:hover{
      border-bottom: 4px solid #3770FF;
      color: transparent;
      background-image: linear-gradient(to bottom,transparent 50%, rgba(24,164,255,0.1), rgba(33, 229, 255, 0.1));
      .item-text{
        color: #3770FF;
      }
    }
    &.enter{
      .item-icon{
        animation: enter .2s steps(16) forwards;
      }
    }
    &.leave{
      .item-icon{
        animation: leave .2s steps(16) forwards;
      }
    }
    @keyframes enter {
      from {
        background-position: center 0;
      }
      to {
        background-position: center -1195px;
      }
    }
    @keyframes leave {
      from {
        background-position: center -1195px;
      }
      to {
        background-position: center 0;
      }
    }
  }
}
.ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  &.two{
    -webkit-line-clamp: 2;
  }
}
</style>
