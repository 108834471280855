const routes = [
    {
        path: "/",
        redirect: '/index'
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('@/pages/index')
    },
    {
        path: '/solve',
        name: 'solve',
        component: () => import('@/pages/solve')
    },
    {
        path: '/product',
        name: 'product',
        component: () => import('@/pages/product')
    },
    {
        path: '/case',
        name: 'case',
        component: () => import('@/pages/case')
    },
    {
        path: '/innovate',
        name: 'innovate',
        // component: () => import('@/pages/innovate')
        component: () => import('@/pages/point')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/pages/about')
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('@/pages/test')
    },
    {
        path: '/detail',
        name: 'detail',
        component: () => import('@/pages/detail')
    }
]


export default routes