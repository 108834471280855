import Vue from 'vue'

import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        // 测试URL
        // baseUrl:'https://yunchuang.webmanage.wulinru.com/jeecg-boot/sys/common/static/',
        // 生产URL
        baseUrl:'https://www-cm.introtec.cn/jeecg-boot/sys/common/static/',
        banner:[],
        footerObj:{},
        docunmentList:[],
        caseList:[],
        dynamic:[],
        solve:[],
        product:[]
    },
    mutations: {
        setBanner:(state,payload) => {
            state.banner = payload
        },
        setObj:(state,payload) => {
            state.footerObj = payload
        },
        setDocument:(state,payload) => {
            state.docunmentList = payload
        },
        setCase:(state,payload) => {
            state.caseList = payload
        },
        setDynamic:(state,payload) => {
            state.dynamic = payload
        },
        setSolve:(state,payload) => {
            state.solve = payload
        },
        setProduct:(state,payload) => {
            state.product = payload
        },
    },
    getters: {},
    actions: {}
})
